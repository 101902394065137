const CODE_OK = 'OK';
const CODE_NOK = 'NOK';
const DEFAULT_ERROR_MESSAGE = 'Ha ocurrido un error. Por favor contacte a soporte.';
const FORBIDDEN_ERROR_MESSAGE = 'No cuentas con autorización para realizar esta acción';

const DOCS = '/docs/index.html';

const DEFAULT_PASSENGER_NAME = 'PAX';

const REPORTS = Object.freeze({
    BUSINESS_ORDER: 'BUSINESS_ORDER',
    SERVICE_ORDER: 'SERVICE_ORDER',
    PAYABLE_ACCOUNTS: 'PAYABLE_ACCOUNTS',
    RECEIVABLE_ACCOUNTS: 'RECEIVABLE_ACCOUNTS'
});

module.exports = {
    CODE_OK,
    CODE_NOK,
    DEFAULT_ERROR_MESSAGE,
    FORBIDDEN_ERROR_MESSAGE,
    DOCS,
    REPORTS,
    DEFAULT_PASSENGER_NAME
};